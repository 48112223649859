// extracted by mini-css-extract-plugin
export var divider = "session-list-module--divider--0008f";
export var introContainer = "session-list-module--introContainer--505f2";
export var sessionCard = "session-list-module--sessionCard--1bd82";
export var sessionCardInformation = "session-list-module--session-card-information--7a2f0";
export var sessionList = "session-list-module--session-list--14dab";
export var sessionTitle = "session-list-module--session-title--e48c3";
export var speakerAbout = "session-list-module--speaker-about--30942";
export var speakerCard = "session-list-module--speakerCard--10498";
export var speakerCardDirection = "session-list-module--speakerCard-direction--84ee9";
export var speakerCardLarge = "session-list-module--speakerCard-large--5e9d0";
export var speakerInfo = "session-list-module--speaker-info--6994c";
export var speakerName = "session-list-module--speaker-name--ceda3";
export var speakerSocial = "session-list-module--speaker-social--6f73c";
export var voiceCard = "session-list-module--voice-card--6a174";
export var voiceCardContent = "session-list-module--voice-card-content--e6c84";