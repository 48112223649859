import cn from "classnames"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Play as IconPlay } from "phosphor-react"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Container from "../../freestanding/containers/container"
import Grid from "../../freestanding/containers/grid"
import { pb32 } from "../../freestanding/utils/padding.module.css"
import * as styles from "./embed.module.css"

interface Props {
  embedId: string
  thumbnail?: IGatsbyImageData | string
  fluid?: boolean
  paddingBottom?: boolean
}

const YoutubeEmbed = ({
  embedId,
  thumbnail,
  fluid = true,
  paddingBottom = true,
}: Props) => {
  const [loadVideo, setLoadVideo] = useState(false)

  const onPlayButtonClick = () => {
    setLoadVideo(true)
  }

  const imageComponent = thumbnail ? (
    <div onClick={onPlayButtonClick} className={cn(styles.wrapper)}>
      {typeof thumbnail == "string" ? (
        <img
          width={"100%"}
          height={"100%"}
          loading="lazy"
          src={thumbnail}
          // TODO: add better alt text
          alt="video thumbnail"
          className={cn(styles.thumbnail)}
        />
      ) : (
        <GatsbyImage
          className={cn(styles.thumbnail)}
          image={getImage(thumbnail)!}
          // TODO: add better alt text
          alt="video thumbnail"
        />
      )}
      <div className={cn(styles.thumbnailOverlay)}>
        <IconPlay
          weight="fill"
          size={48}
          color="#5528ff"
          onClick={onPlayButtonClick}
          className={cn(styles.playButton)}
        />
      </div>
    </div>
  ) : undefined

  const videoUrl = loadVideo
    ? "https://www.youtube-nocookie.com/embed/" + embedId + "?autoplay=1"
    : "https://www.youtube-nocookie.com/embed/" + embedId

  return (
    <Container fluid={fluid} justify={"center"}>
      <Grid
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={paddingBottom ? cn(pb32) : ""}
      >
        {!thumbnail || loadVideo ? (
          <div className={cn(styles.videoContainer)}>
            <iframe
              className={cn(styles.video)}
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          imageComponent
        )}
      </Grid>
    </Container>
  )
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
}

export default YoutubeEmbed
