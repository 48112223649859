import cn from "classnames"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Speaker } from "../../../templates/summit"
import Button from "../../freestanding/button/button"
import Container from "../../freestanding/containers/container"
import Grid from "../../freestanding/containers/grid"
import { pb16, pb8, pt32 } from "../../freestanding/utils/padding.module.css"
import * as styles from "./session-list.module.css"

interface PropTypes {
  speaker: Speaker
}

const SpeakerCard = ({ speaker }: PropTypes) => {
  return (
    <Grid lg={4} md={4} sm={6} xs={12} className={styles.speakerCard}>
      <Button
        key={speaker.id}
        to={speaker.social}
        openInNewWindow={true}
        style="none"
      >
        <article>
          <Container
            key={speaker.id}
            className={cn(styles.speakerSocial, pb16)}
          >
            <Container
              flexContainer={"column"}
              justify={"center"}
              alignItems={"center"}
            >
              <GatsbyImage
                image={getImage(speaker.speakerImage.childImageSharp)!}
                alt={speaker.fullname}
              />
              <Container
                flexContainer={"column"}
                alignItems={"center"}
                className={cn("text-is-centered", pt32)}
              >
                <h6 className={cn(pb8)}>{speaker.fullname}</h6>
                <p className={cn("font-p-small", styles.speakerName)}>
                  {speaker.title} at {speaker.company}
                </p>
              </Container>
            </Container>
          </Container>
        </article>
      </Button>
    </Grid>
  )
}

export default SpeakerCard
