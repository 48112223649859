import { graphql } from "gatsby"
import Layout from "../../components/layouts/layout/layout"
import DiversityStatement from "../../components/layouts/summit/diversity-statement"
import SessionList from "../../components/layouts/summit/session-list"
import * as content from "../../page-content/events/content-summit-21"
import { verticalNav } from "../../page-content/events/content-summit-21"
import { Session, Speaker } from "../../templates/summit"

interface Props {
  data: {
    speakers: {
      nodes: {
        frontmatter: Speaker
      }[]
    }
    sessions: {
      nodes: {
        id: string
        frontmatter: Session
      }[]
    }
  }
}

const SummitPage = ({ data }: Props) => (
  <Layout
    theme="info"
    summit={{ verticalNav, year: 2021 }}
    showAnnouncement={false}
  >
    <SessionList
      {...content.sessionList}
      speakers={data.speakers}
      sessions={data.sessions}
    />
    <DiversityStatement {...content.diversityStatement} />
  </Layout>
)

export default SummitPage

export const Head = () => {
  return (
    <>
      <title>{content.seo.title}</title>
      {content.seo.description && (
        <meta name="description" content={content.seo.description} />
      )}
      <meta property="og:title" content={content.seo.title} />
      {content.seo.description && (
        <meta property="og:description" content={content.seo.description} />
      )}
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="ORY" />
      <meta property="twitter:title" content={content.seo.title} />
      {content.seo.description && (
        <meta
          property="twitter:description"
          content={content.seo.description}
        />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query {
    speakers: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/summit/summit-21/speakers/" }
        frontmatter: { type: { eq: "summit-speaker" } }
      }
    ) {
      nodes {
        frontmatter {
          id
          fullname
          speakerImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          social
          title
          company
          about
        }
      }
    }
    sessions: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/summit/summit-21/sessions/" }
        frontmatter: { ready: { ne: false } }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          seo {
            title
            description
          }
          order
          title
          description
          ready
          banner {
            childImageSharp {
              gatsbyImageData(
                blurredOptions: { width: 100 }
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
                aspectRatio: 1.8
              )
            }
          }
          speakers
          slug
        }
      }
    }
  }
`
