import cn from "classnames"
import { Quotes as IconQuotes } from "phosphor-react"
import React from "react"
import Container from "../../freestanding/containers/container"
import Grid from "../../freestanding/containers/grid"
import YoutubeEmbed from "../embed/embed"
import { pb16 } from "../../freestanding/utils/padding.module.css"
import * as styles from "./session-list.module.css"

interface PropTypes {
  video: {
    youTubeId: string
    thumbnail: string
  }
  description: string
  person: string
  jobTitle: string
}

const VoiceCard = ({ video, description, person, jobTitle }: PropTypes) => {
  return (
    <Grid lg={6} md={6} sm={12} xs={12} className={styles.voiceCard}>
      <YoutubeEmbed
        embedId={video.youTubeId}
        thumbnail={video.thumbnail}
        fluid={false}
        paddingBottom={false}
      />
      <Container
        flexContainer="column"
        alignItems={"start"}
        className={styles.voiceCardContent}
      >
        <IconQuotes
          weight="fill"
          size={32}
          className={cn(pb16, "themed-primary")}
        />
        <p className={cn(pb16)}>{description}</p>
        <p className={cn("font-p-small", "is-monospace")}>
          <span className={cn("is-semibold")}>{person}</span>
        </p>
        <p className={cn("font-p-small", "is-monospace")}>{jobTitle}</p>
      </Container>
    </Grid>
  )
}

export default VoiceCard
