import cn from "classnames"
import React from "react"
import Grid from "../../freestanding/containers/grid"
import { pb32, pb64 } from "../../freestanding/utils/padding.module.css"
import * as styles from "./diversity-statement.module.css"

export interface PropTypes {
  id: string
  title: string
  text: string | React.ReactElement
  backgroundImageUrl: string
}

const DiversityStatement = ({
  id,
  text,
  title,
  backgroundImageUrl,
}: PropTypes) => {
  return (
    <div
      className={cn(styles.container)}
      id={id}
      style={{
        backgroundImage: "url(" + backgroundImageUrl + ")",
      }}
    >
      <Grid lg={6} md={9} sm={9} xs={9} className={pb64}>
        <h3 className={pb32}>{title}</h3>
        <p>{text}</p>
      </Grid>
    </div>
  )
}

export default DiversityStatement
