import cn from "classnames"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Session, Speaker } from "../../../templates/summit"
import Button from "../../freestanding/button/button"
import Container from "../../freestanding/containers/container"
import Grid from "../../freestanding/containers/grid"
import { pb16, pb32, pb8 } from "../../freestanding/utils/padding.module.css"
import * as styles from "./session-list.module.css"

interface PropTypes {
  session: Session
  year: number
  speakers: Speaker[]
}

const SessionCard = ({ session, year, speakers }: PropTypes) => {
  const banner = getImage(session.banner.childImageSharp)
  return (
    <Grid lg={6} md={6} sm={12} xs={12} className={styles.sessionCard}>
      <Button
        key={session.slug}
        to={`/summit/${year}/${session.slug}`}
        style="none"
      >
        <article>
          {banner ? <GatsbyImage image={banner} alt={session.title} /> : null}
          <Container className={styles.sessionCardInformation}>
            <h3 className={cn(pb32, styles.sessionTitle)}>{session.title}</h3>
            <Container justify={"start"}>
              {speakers.map((speaker, idx) =>
                speaker ? (
                  <Container
                    key={idx}
                    className={cn(styles.speakerSocial, pb16)}
                  >
                    <Container
                      flexContainer={"row"}
                      justify={"start"}
                      alignItems={"center"}
                    >
                      <GatsbyImage
                        image={getImage(speaker.speakerImage.childImageSharp)!}
                        alt={speaker.fullname}
                        className={cn("responsive", pb16)}
                      />
                      <Container
                        flexContainer={"column"}
                        alignItems={"start"}
                        className={cn(pb8)}
                      >
                        <h6>{speaker.fullname}</h6>
                        <p className={"font-p-small"}>
                          {speaker.title} at {speaker.company}
                        </p>
                      </Container>
                    </Container>
                  </Container>
                ) : null,
              )}
            </Container>
          </Container>
        </article>
      </Button>
    </Grid>
  )
}

export default SessionCard
