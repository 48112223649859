import cn from "classnames"
import { getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Session, Speaker } from "../../../templates/summit"
import Container from "../../freestanding/containers/container"
import Grid from "../../freestanding/containers/grid"
import YoutubeEmbed from "../embed/embed"
import SessionCard from "./session-card"
import SpeakerCard from "./speaker-card"
import VoicesCard from "./voices-card"
import { pb16, pb32, pb64 } from "../../freestanding/utils/padding.module.css"
import * as styles from "./session-list.module.css"

export interface PropTypes {
  id: string
  title: string
  intro: string | React.ReactElement
  sessionText: string | React.ReactElement
  speakerText: string | React.ReactElement
  year: number
  introBannerUrl: string
  speakers: {
    nodes: {
      frontmatter: Speaker
    }[]
  }
  sessions: {
    nodes: {
      frontmatter: Session
    }[]
  }
  aftermovie?: {
    youTubeId: string
    thumbnail: string
  }
  voices?: {
    video: {
      youTubeId: string
      thumbnail: string
    }
    description: string
    person: string
    jobTitle: string
  }[]
}

const SessionList = ({
  id,
  title,
  intro,
  sessionText,
  speakerText,
  year,
  introBannerUrl,
  speakers,
  sessions,
  aftermovie,
  voices,
}: PropTypes) => {
  return (
    <div id={id} className={cn(styles.sessionList)}>
      <Container alignItems={"start"}>
        <Grid lg={12} md={12} sm={12} xs={12}>
          <Container alignItems="start">
            {aftermovie ? (
              <YoutubeEmbed
                embedId={aftermovie.youTubeId}
                thumbnail={aftermovie.thumbnail}
              />
            ) : (
              <div
                className={styles.introContainer}
                style={{ backgroundImage: "url(" + introBannerUrl + ")" }}
              >
                <h1 className={"font-display"}>{title}</h1>
              </div>
            )}
            <p className={"font-p-display"}>{intro}</p>
          </Container>
          <hr className={styles.divider} aria-hidden="true" />
          {voices ? (
            <>
              <Container justify={"start"}>
                <Container>
                  <h2 id="voices" className={cn(pb64)}>
                    Voices
                  </h2>
                </Container>
                <Container>
                  {voices?.map((voice) => (
                    <VoicesCard
                      video={voice.video}
                      description={voice.description}
                      person={voice.person}
                      jobTitle={voice.jobTitle}
                    />
                  ))}
                </Container>
              </Container>
              <hr className={styles.divider} aria-hidden="true" />
            </>
          ) : null}
          <Container justify={"start"}>
            <Container className={cn(pb64)}>
              <h2 id="sessions" className={cn(pb32)}>
                Sessions
              </h2>
              <p>{sessionText}</p>
            </Container>
            <Container alignItems={"start"} justify={"start"}>
              {sessions.nodes.map((session) => (
                <SessionCard
                  session={session.frontmatter}
                  year={year}
                  speakers={speakers.nodes
                    .map((speaker) => speaker.frontmatter)
                    .filter((speaker) =>
                      session.frontmatter.speakers.includes(speaker.id),
                    )}
                />
              ))}
            </Container>
          </Container>
          <hr className={styles.divider} aria-hidden="true" />
          <Container justify={"start"}>
            <Container className={cn(pb64)}>
              <h2 id="speakers" className={cn(pb32)}>
                Speakers
              </h2>
              <p>{speakerText}</p>
            </Container>
            <Container alignItems={"start"} justify={"start"}>
              {speakers.nodes.map((speaker) => (
                <SpeakerCard speaker={speaker.frontmatter} />
              ))}
            </Container>
          </Container>
          <hr className={styles.divider} aria-hidden="true" />
        </Grid>
      </Container>
    </div>
  )
}

export default SessionList
